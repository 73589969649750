body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
.app {
    background: url('./components/login/duck.svg'),
        url('./components/login/duck.svg');
    background-position-x: -53.73%, 96.93%;
    background-position-y: 43.54%, -204.64%;
    background-repeat: no-repeat;
}
